<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div class="sidebar-shop" :class="{'show': mqShallShowLeftSidebar}">
        <!-- Filters -->
        <b-overlay :show="categoriesLoading || loadProducts" rounded spinner-medium spinner-variant="primary">
          <b-card>
            <h6 class="filter-heading d-none d-lg-block mb-1">
              {{ $t('trip.shop.filters') }}
            </h6>

            <!-- Categories -->
            <div class="product-categories">
              <tree
                :items="categories"
                :items-ids-selected="categoriesSelected"
                @items:selected="setCategories($event)"
              />
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>

    <div class="body-content-overlay" :class="{ show: mqShallShowLeftSidebar }" @click="$emit('update:mq-shall-show-left-sidebar', false)" />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import Tree from '@/components/Tree.vue'
import { mapActions, mapState } from 'vuex'
// import { mapFields } from 'vuex-map-fields'

export default defineComponent({
  name: 'ShopListLeftFilterSidebar',

  components: {
    Tree,
  },
  props: {
    params: {
      type: Object,
      required: false,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: false,
    },
    loadProducts: {
      type: Boolean,
      required: false,
    },
  },

  computed: {
    ...mapState('category', ['categories', 'categoriesLoading']),
    ...mapState('shop', ['categoriesSelected']),
  },

  mounted() {
    this.fetchCategories(this.categoriesSelected)
  },

  methods: {
    ...mapActions('category', ['fetchCategories']),
    ...mapActions('shop', ['selectCategories']),

    setCategories(catIds) {
      this.selectCategories(catIds)
      this.$emit('categorieSelected', catIds)
    },
  },
})
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.sidebar-detached .sidebar {
  width: initial;
}
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
</style>
